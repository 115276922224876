import {
  Button,
  Container,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  Grid2,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import {
  settingsDocument,
  updateManagerLocaleDocument,
} from '@social-garden/api/documents/manager.ts';
import { formatBytes } from '@social-garden/utils/helpers.ts';
import Section from '../../components/Section.tsx';
import ErrorBoundary from '../../components/ErrorBoundary.tsx';
import { ColorMode } from '../../contexts/colorModeContext.ts';
import useColorMode from '../../hooks/useColorMode.ts';
import useCampaignUsage from '../../hooks/useCampaignUsage.ts';
import useBrandUsage from '../../hooks/useBrandUsage.ts';
import SubscriptionPlanItem from '../../components/SubscriptionPlanItem.tsx';
import PageTitle from '../../components/PageTitle.tsx';
import useStorageSpaceUsage from '../../hooks/useStorageSpaceUsage.ts';

export default function Settings() {
  const {
    t,
    i18n: { changeLanguage, resolvedLanguage, languages },
  } = useTranslation(['common', 'manager']);
  const { mode, setMode } = useColorMode();
  const { data } = useSuspenseQuery(settingsDocument);
  const { pathname } = useLocation();
  const [updateManagerLocale, { loading }] = useMutation(
    updateManagerLocaleDocument,
  );
  const {
    max: maxCampaignUsage,
    current: currentCampaignUsage,
    exceeded: campaignUsageExceeded,
  } = useCampaignUsage();
  const {
    max: maxBrandUsage,
    current: currentBrandUsage,
    exceeded: brandUsageExceeded,
  } = useBrandUsage();
  const {
    max: maxStorageSpaceUsage,
    current: currentStorageSpaceUsage,
    exceeded: storageSpaceUsageExceeded,
  } = useStorageSpaceUsage();

  const returnUrl = useMemo(
    () => window.location.origin + pathname,
    [pathname],
  );

  const campaignUsagePercentage = useMemo(() => {
    if (maxCampaignUsage === null) {
      return 0;
    }
    if (maxCampaignUsage === 0 || currentCampaignUsage >= maxCampaignUsage) {
      return 100;
    }
    return (currentCampaignUsage / maxCampaignUsage) * 100;
  }, [currentCampaignUsage, maxCampaignUsage]);

  const brandUsagePercentage = useMemo(() => {
    if (maxBrandUsage === null) {
      return 0;
    }
    if (maxBrandUsage === 0 || currentBrandUsage >= maxBrandUsage) {
      return 100;
    }
    return (currentBrandUsage / maxBrandUsage) * 100;
  }, [currentBrandUsage, maxBrandUsage]);

  const storageSpaceUsagePercentage = useMemo(() => {
    if (
      maxStorageSpaceUsage === 0 ||
      currentStorageSpaceUsage >= maxStorageSpaceUsage
    ) {
      return 100;
    }
    return (currentStorageSpaceUsage / maxStorageSpaceUsage) * 100;
  }, [currentStorageSpaceUsage, maxStorageSpaceUsage]);

  const handleOnLanguageChange = useCallback(
    async (event: SelectChangeEvent<string>) => {
      await Promise.all([
        changeLanguage(event.target.value),
        updateManagerLocale({
          variables: {
            input: {
              locale: event.target.value,
            },
          },
        }),
      ]);
    },
    [changeLanguage, updateManagerLocale],
  );

  const handleOnChange = useCallback(
    (event: SelectChangeEvent<ColorMode>) => {
      setMode(event.target.value as ColorMode);
    },
    [setMode],
  );

  if (data.whoAmI.__typename !== 'Manager') {
    return <ErrorBoundary />;
  }

  return (
    <Container>
      <Grid2 container spacing={4}>
        <Grid2 size={12}>
          <PageTitle>{t('manager:settings.title')}</PageTitle>
        </Grid2>
        {data.whoAmI.company.currentSubscription?.subscriptionPlan ? (
          <Grid2
            size={{
              xs: 12,
              md: 6,
            }}>
            <Section header={t('common:subscription')}>
              <SubscriptionPlanItem
                sx={{
                  borderColor: (theme) => theme.palette.secondary.main,
                }}
                subscriptionPlan={
                  data.whoAmI.company.currentSubscription.subscriptionPlan
                }
                disablePriceHighlighting
              />
            </Section>
          </Grid2>
        ) : null}
        <Grid2
          size={{
            xs: 12,
            md: 'grow',
          }}>
          <Stack spacing={4}>
            <Section header={t('manager:settings.header.general')}>
              <FormControl fullWidth>
                <InputLabel>{t('manager:settings.language')}</InputLabel>
                <Select
                  label={t('manager:settings.language')}
                  disabled={loading}
                  value={resolvedLanguage ?? languages[0]}
                  onChange={handleOnLanguageChange}>
                  <MenuItem value="en">{t('common:locale.en')}</MenuItem>
                  <MenuItem value="de">{t('common:locale.de')}</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel>{t('manager:settings.theme')}</InputLabel>
                <Select<ColorMode>
                  label={t('manager:settings.theme')}
                  value={mode}
                  onChange={handleOnChange}>
                  <MenuItem value="system">{t('common:theme.system')}</MenuItem>
                  <MenuItem value="light">{t('common:theme.light')}</MenuItem>
                  <MenuItem value="dark">{t('common:theme.dark')}</MenuItem>
                </Select>
              </FormControl>
            </Section>
            <Section header={t('manager:settings.header.usage')}>
              <Stack>
                <Typography>
                  {t('manager:company.usage.campaign', {
                    current: currentCampaignUsage,
                    max: maxCampaignUsage === null ? '∞' : maxCampaignUsage,
                  })}
                </Typography>
                <LinearProgress
                  sx={{
                    borderRadius: 5,
                    height: 5,
                  }}
                  variant="determinate"
                  color="secondary"
                  value={campaignUsagePercentage}
                />
              </Stack>
              <Stack>
                <Typography>
                  {t('manager:company.usage.brand', {
                    current: currentBrandUsage,
                    max: maxBrandUsage === null ? '∞' : maxBrandUsage,
                  })}
                </Typography>
                <LinearProgress
                  sx={{
                    borderRadius: 5,
                    height: 5,
                  }}
                  variant="determinate"
                  color="secondary"
                  value={brandUsagePercentage}
                />
              </Stack>
              <Stack>
                <Typography>
                  {t('manager:company.usage.storageSpace', {
                    current: formatBytes(
                      currentStorageSpaceUsage * 1024 * 1024,
                    ),
                    max: formatBytes(maxStorageSpaceUsage * 1024 * 1024),
                  })}
                </Typography>
                <LinearProgress
                  sx={{
                    borderRadius: 5,
                    height: 5,
                  }}
                  variant="determinate"
                  color="secondary"
                  value={storageSpaceUsagePercentage}
                />
              </Stack>
              <Button
                fullWidth
                variant={
                  campaignUsageExceeded ||
                  brandUsageExceeded ||
                  storageSpaceUsageExceeded
                    ? 'contained'
                    : 'outlined'
                }
                size="large"
                color="secondary"
                href={`mailto:${import.meta.env.VITE_SALES_EMAIL}`}>
                {t('common:contactSales')}
              </Button>
            </Section>
            <Button
              fullWidth
              variant="outlined"
              size="large"
              href={`/customer-portal?return-url=${returnUrl}`}>
              {t('manager:manageStripeAccountButton')}
            </Button>
          </Stack>
        </Grid2>
      </Grid2>
    </Container>
  );
}
